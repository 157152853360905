import * as React from "react";
import PropTypes from "prop-types";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

const useCompStyle = makeStyles((theme: Theme) =>
  createStyles({
    component: {
      borderWidth: 0.5, 
      borderRadius: 4, 
      borderColor: theme.palette.grey[700],
      borderStyle: 'solid',
      display: 'flex', 
      flexDirection: 'column',
      padding: 4,
      "&:hover": {
        borderColor: theme.palette.grey[100]
      }
    }
  })
);


const FieldValue = (props) => {
  const classes = useCompStyle();

  return(
    <fieldset className={clsx(classes.component, props.containerClassName) } style={props.containerStyle}>
      <legend>{props.label}</legend>
      <div className={props.valueClassName} style={props.valueStyle}>
        {props.value}
      </div>
    </fieldset>
  );
}

FieldValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  containerStyle: PropTypes.object,
  containerClassName: PropTypes.string,
  valueStyle: PropTypes.object,
  valueClassName: PropTypes.string
}

// FieldValue.defaultProps = {
  
// }

export default FieldValue;
