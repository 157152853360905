import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/Layout";
import clsx from 'clsx';
import useCommonStyles from '../../assets/jss/commonStyles';
// import useProfileStyles from '../../assets/jss/profileStyles';
// import Seo from "../../components/Seo";

import { 
  Button, Paper, TextField, Select, 
  FormControl, InputLabel, MenuItem, IconButton, 
  Input, Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import * as MUIIcons from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {DropzoneArea} from 'material-ui-dropzone';

import Helper from '@justnice/helper';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { connect } from 'react-redux';
import * as systemActions from '../../redux/actions/systemActions';
import * as sessionActions from '../../redux/actions/sessionActions';
import * as uiActions from '../../redux/actions/uiActions';
// import AssetForm from './form';
import Image from '../../components/Image';
import FieldValue from '../../components/FieldValue';

const profileFormInitialState = () => {
  return {
    first_name: {value: '', touched: false, error: false, message: ''},
    last_name: {value: '', touched: false, error: false, message: ''},
    mid_name: {value: '', touched: false, error: false, message: ''},

    dob: {value: '', touched: false, error: false, message: ''},
    gender: {value: '', touched: false, error: false, message: ''},
    photo_id: {value: null, touched: false, error: false, message: ''},

    address_line1: {value: '', touched: false, error: false, message: ''},
    address_line2: {value: '', touched: false, error: false, message: ''},
    address_line3: {value: '', touched: false, error: false, message: ''},
    postal_code: {value: '', touched: false, error: false, message: ''},
    country_code: {value: '', touched: false, error: false, message: ''},
    state_code: {value: '', touched: false, error: false, message: ''}
  };
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ProfilePage : React.FC = (props) => {
  const {t} = useTranslation('profile');
  const classes = useCommonStyles();
  // const profileClasses = useProfileStyles();
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [profileForm, setProfileForm] = React.useState(profileFormInitialState());
  const [profilePhoto, setProfilePhoto] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [successMsg, setSuccessMsg] = React.useState('');
  
  const locale = props['ui'].get('lang');
  const sysList = Helper.getStateAsJSObj(props['system'], 'sysList.data', []);
  const myProfile = Helper.getStateAsJSObj(props['session'], 'myProfile.data', null);
  const countryList = Helper.arrayHasItem(sysList) ? sysList.filter(item => item.category === 'COUNTRY') : [];

  React.useEffect(() => {
    const sysListObj = Helper.getStateAsJSObj(props['system'], 'sysList', null);
    
    // If no system list info and system list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(sysListObj) || 
       (!Helper.arrayHasItem(sysListObj?.data) && !sysListObj?.loading) ||
       (sysListObj?.locale !== locale && !sysListObj?.loading)) {
      props.dispatch(
        systemActions.sysListGet(locale)
      );
    }

    props.dispatch(
      sessionActions.myProfileGet((success, result) => {
        if(success && Helper.isNotNullAndUndefined(result, ['data'])) {
          // console.log('My Profile Success: ', result);
          let user = result.data;
          let initForm = profileFormInitialState();
          
          initForm.first_name.value = Helper.isNotNullAndUndefined(user, ['name', 'first_name']) ? user.name.first_name : '';
          initForm.last_name.value = Helper.isNotNullAndUndefined(user, ['name', 'last_name']) ? user.name.last_name : '';
          initForm.mid_name.value = Helper.isNotNullAndUndefined(user, ['name', 'mid_name']) ? user.name.mid_name : '';

          initForm.dob.value = Helper.isNotNullAndUndefined(user, ['bio_info', 'dob']) ? user.bio_info.dob : '';
          initForm.gender.value = Helper.isNotNullAndUndefined(user, ['bio_info', 'gender']) ? user.bio_info.gender : '';
          
          initForm.address_line1.value = Helper.isNotNullAndUndefined(user.addresses[0], ['line1']) ? user.addresses[0].line1 : '';
          initForm.address_line2.value = Helper.isNotNullAndUndefined(user.addresses[0], ['line2']) ? user.addresses[0].line2 : '';
          initForm.address_line3.value = Helper.isNotNullAndUndefined(user.addresses[0], ['line3']) ? user.addresses[0].line3 : '';
          initForm.postal_code.value = Helper.isNotNullAndUndefined(user.addresses[0], ['postal_code']) ? user.addresses[0].postal_code : '';
          initForm.country_code.value = Helper.isNotNullAndUndefined(user.addresses[0], ['country_code']) ? user.addresses[0].country_code : '';
          initForm.state_code.value = Helper.isNotNullAndUndefined(user.addresses[0], ['state_code']) ? user.addresses[0].state_code : '';

          // console.log('initForm: ', initForm);
          setProfileForm(initForm);

          if(Helper.stringHasValue(user.bio_info?.photo_id?.formats?.medium?.url)) {
            setProfilePhoto(user.bio_info.photo_id.formats.medium.url);
          }
        }
      })
    );
  }, []);

  const onFieldChange = (field: string, value : any) => {
    let localProfileForm = Helper.deepCopy(profileForm);
    localProfileForm[field].value = value;
    localProfileForm[field].touched = true;
    setProfileForm(localProfileForm);

    // console.log('onFieldChange: ', localProfileForm);
  };

  const validateForm = (form=null) => {
    let localForm = Helper.isNotNullAndUndefined(form) ? form : Helper.deepCopy(profileForm);
    let formIsValid = true;

    let fields = Object.keys(localForm);

    fields.forEach(item => {
      localForm[item].error = false;
      localForm[item].message = '';

      if(localForm[item].touched && !['keyword', 'paymentType', 'orderStatus'].includes(item)) {

        if(item === 'orderDate') {
          if(!Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.startValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.endValue');
            formIsValid = false;
          }

          if(!Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.noValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            let start = new Date(localForm[item].start);
            let end = new Date(localForm[item].end);
            if(start.getTime() > end.getTime()) {
              localForm[item].error = true;
              localForm[item].message = t('error.message.invalidRange');
              formIsValid = false;
            }
          }
        }
        else {
          if(!Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.startValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.endValue');
            formIsValid = false;
          }

          if(!Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.noValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            let start = eval(localForm[item].start);
            let end = eval(localForm[item].end);
            if(start > end) {
              localForm[item].error = true;
              localForm[item].message = t('error.message.invalidRange');
              formIsValid = false;
            }
          }
        }
      }
    });

    setProfileForm(localForm);
    return formIsValid;
  }

  const clearProfileForm = () => {
    setProfileForm(profileFormInitialState());
  }

  const getCodeText = (category, code) => {
    if(sysList.length <= 0)
      return '';

    let codeItem = sysList.find(item => item.category === category && item.code === code);
    if(!Helper.isNotNullAndUndefined(codeItem)) {
      return '';
    }

    return codeItem.text
  }

  const resetProfile = () => {
    let initForm = profileFormInitialState();
    
    initForm.first_name.value = myProfile.name.first_name;
    initForm.last_name.value = myProfile.name.last_name;
    initForm.mid_name.value = myProfile.name.mid_name;

    initForm.dob.value = Helper.isNotNullAndUndefined(myProfile, ['bio_info', 'dob']) ? myProfile.bio_info.dob : '';
    initForm.gender.value = Helper.isNotNullAndUndefined(myProfile, ['bio_info', 'gender']) ? myProfile.bio_info.gender : '';
    
    initForm.address_line1.value = Helper.isNotNullAndUndefined(myProfile.addresses[0], ['line1']) ? myProfile.addresses[0].line1 : '';
    initForm.address_line2.value = Helper.isNotNullAndUndefined(myProfile.addresses[0], ['line2']) ? myProfile.addresses[0].line2 : '';
    initForm.address_line3.value = Helper.isNotNullAndUndefined(myProfile.addresses[0], ['line3']) ? myProfile.addresses[0].line3 : '';
    initForm.postal_code.value = Helper.isNotNullAndUndefined(myProfile.addresses[0], ['postal_code']) ? myProfile.addresses[0].postal_code : '';
    initForm.country_code.value = Helper.isNotNullAndUndefined(myProfile.addresses[0], ['country_code']) ? myProfile.addresses[0].country_code : '';
    initForm.state_code.value = Helper.isNotNullAndUndefined(myProfile.addresses[0], ['state_code']) ? myProfile.addresses[0].state_code : '';

    setProfileForm(initForm);
  }

  const updateProfile = () => {
    const formData = new FormData();
    const hasPhoto = Helper.isNotNullAndUndefined(profilePhoto);
    let useFormData = false;
    let profile = {
      name: {
        first_name: profileForm.first_name.value, 
        mid_name: '', 
        last_name: profileForm.last_name.value
      },
      bio_info: {
        gender: profileForm.gender.value,
        dob: profileForm.dob.value
      },
      addresses: [{
        category: 'PERSONAL',
        line1: profileForm.address_line1.value,
        line2: profileForm.address_line2.value,
        line3: profileForm.address_line3.value,
        postal_code: profileForm.postal_code.value,
        state_code: profileForm.state_code.value,
        country_code: profileForm.country_code.value
      }],
      removePhoto: false
    };

    if(!hasPhoto) {
      profile.removePhoto = true; // If no profilePhoto is speicfy, instruct the server side to remove any photo assign to the user
    }

    props.dispatch(
      uiActions.setBusyStatus(true)
    );

    if(hasPhoto && typeof profilePhoto === 'object') {
      formData.append('files.bio_info.photo_id', profilePhoto, profilePhoto.name);
      formData.append('data', JSON.stringify(profile));
      useFormData = true;
    }

    props.dispatch(
      sessionActions.myProfileUpdate(useFormData ? formData : profile, (success, result) => {
        props.dispatch(
          uiActions.setBusyStatus(false)
        );

        if(success) {
          setSuccessMsg(t('message.updateProfileSuccess'));
        }
        else {
          setErrorMsg(t('message.updateProfileFailed'))
        }
      })
    );
  }

  return (
    <Layout title={t('pageTitle')}>
      {/* <Seo title={t('title')} /> */}
      <Paper variant="outlined" 
        className={clsx(classes.form, classes.nonPrintContent)}
        // style={{ height: tableHeight, width: '100%' }}
      >
        <div className={classes.formRow}>
          <div className={clsx(classes.formCol)} style={{flex: 7}} >
            <div className={classes.formRow}>
              <FieldValue containerStyle={{flex: 1}} label="Account Email" value={myProfile?.user?.email} />
              <span style={{flex: 1}}></span>
            </div>
            {/* User Name */}
            <div className={classes.formRow}>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-firstname">{t('profile.field.firstname')}</InputLabel>
                <Input
                  id="profile-firstname"
                  value={profileForm.first_name.value}
                  onChange={event => onFieldChange('first_name', event.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-lastname">{t('profile.field.lastname')}</InputLabel>
                <Input
                  id="profile-lastname"
                  value={profileForm.last_name.value}
                  onChange={event => onFieldChange('last_name', event.target.value)}
                />
              </FormControl>
            </div>

            {/* User Bio */}
            <div className={classes.formRow}>
              <FormControl variant="outlined" className={classes.formField}>
                <InputLabel id="profile-gender">{t('profile.field.gender')}</InputLabel>
                <Select
                  labelId="profile-gender"
                  id="profile-gender-sel"
                  value={profileForm.gender.value}
                  onChange={event => onFieldChange('gender', event.target.value as string)}
                  // onBlur={(event) => validateFormField('imageFormat', event.target.value)}
                  label={t('profile.field.gender')}
                >
                  {
                    sysList
                    .filter(item => item.category === 'GENDER')
                    .map(item => (
                      <MenuItem value={item.code} key={`GENDER${item.code}`}>
                        {item.text}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-dob">{t('profile.field.dob')}</InputLabel>
                <Input
                  id="profile-dob"
                  type={'date'}
                  value={profileForm.dob.value}
                  onChange={event => onFieldChange('dob', event.target.value)}
                />
              </FormControl>
            </div>
            
            {/* User Address */}
            <div className={classes.formRow}>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-add1">{t('profile.field.address.line1')}</InputLabel>
                <Input
                  id="profile-add1"
                  value={profileForm.address_line1.value}
                  onChange={event => onFieldChange('address_line1', event.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-add2">{t('profile.field.address.line2')}</InputLabel>
                <Input
                  id="profile-add2"
                  value={profileForm.address_line2.value}
                  onChange={event => onFieldChange('address_line2', event.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.formRow}>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-add3">{t('profile.field.address.line3')}</InputLabel>
                <Input
                  id="profile-add3"
                  value={profileForm.address_line3.value}
                  onChange={event => onFieldChange('address_line3', event.target.value)}
                />
              </FormControl>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-state">{t('profile.field.address.state')}</InputLabel>
                <Input
                  id="profile-state"
                  value={profileForm.state_code.value}
                  onChange={event => onFieldChange('state_code', event.target.value)}
                />
              </FormControl>
            </div>
            <div className={classes.formRow}>
              <FormControl className={classes.formField}>
                <InputLabel htmlFor="profile-postal">{t('profile.field.address.postal')}</InputLabel>
                <Input
                  id="profile-postal"
                  value={profileForm.postal_code.value}
                  onChange={event => onFieldChange('postal_code', event.target.value)}
                />
              </FormControl>
              <Autocomplete
                className={classes.formField}
                multiple={false}
                id="countryTag"
                options={countryList}
                freeSolo={false}
                getOptionLabel={(option) => {
                  // console.log('getOptionLabel: ', option);
                  if(typeof option === 'object')
                    return option.text;

                  let selCountry = countryList.find(item => item.code === option);
                  return selCountry?.text;
                }}
                getOptionSelected={(option, value) => {
                  // console.log('getOptionSelected: ', option, value);
                  if(typeof value === 'string')
                    return option.code === value;

                  return option.code === value.code;
                }}
                renderInput={(params) => (
                  <TextField {...params} 
                    variant="outlined" 
                    label={t('profile.field.address.country')} 
                    placeholder={t('profile.field.address.country')} 
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }} />
                )}
                value={profileForm.country_code.value}
                onChange={(event, value, reason) => {
                  // console.log('onChange: ', value);
                  onFieldChange('country_code', Helper.isNotNullAndUndefined(value) ? value.code : '');
                }}
              />
            
            </div>
          </div>
          <div className={clsx(classes.formCol)} style={{flex: 1, justifyContent: 'center', alignItems: 'center'}} >
            {/* <Image width={250} src={process.env.GATSBY_API_URL + myProfile?.bio_info?.photo_id?.url} /> */}
            {
              !Helper.isNotNullAndUndefined(profilePhoto) ?
              <>
                <DropzoneArea
                  filesLimit={1}
                  //clearOnUnmount={false}
                  acceptedFiles={['image/*']}
                  dropzoneText={'Upload your profile photo'}
                  maxFileSize={10485760} // 10mb
                  showPreviews={false}
                  showPreviewsInDropzone={false}
                  onChange={files => setProfilePhoto(Helper.arrayHasItem(files) ? files[0] : null)}
                  initialFiles={null}
                />
              </>
              :
              <div style={{position: 'relative', textAlign: 'center', borderWidth: 3, borderStyle: 'dashed', borderRadius: 10, borderColor: '#999', padding: 10}}>
                <Image width={250} src={typeof profilePhoto === 'object' ? profilePhoto : process.env.GATSBY_API_URL + profilePhoto} />
                <IconButton aria-label="delete" 
                  style={{position: 'absolute', right: 10, top: 10, backgroundColor: '#fff3', width: 50, height: 50, borderRadius: 25}}
                  onClick={() => {
                    setProfilePhoto(null);
                  }}
                >
                  <MUIIcons.Delete />
                </IconButton>
                
              </div>
              
            }
            
          </div>
        </div>
        <div className={classes.formRow}>
          <div className={classes.formRow} style={{flex: 7, justifyContent: 'center'}}>
            <Button variant="contained" onClick={() => resetProfile()}>
              {t('button.reset')}
            </Button>
            <div style={{width: 30}} />
            <Button variant="contained" color="primary" onClick={() => updateProfile()}>
              {t('button.apply')}
            </Button>
          </div>
          
        </div>
        
      </Paper>

      <Snackbar open={errorMsg.length > 0} autoHideDuration={8000} onClose={() => setErrorMsg('')}>
        <Alert onClose={() => setErrorMsg('')} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>

      <Snackbar open={successMsg.length > 0} autoHideDuration={8000} onClose={() => setSuccessMsg('')}>
        <Alert onClose={() => setSuccessMsg('')} severity="success">
          {successMsg}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system'],
    page: state['page']
  };
})(ProfilePage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
